<template>
  <div class="form-summ form-item grid" :class="{ required: required }" :data-success="isSuccess">
    <label v-if="label" class="form-item__label">
      {{ label }}
    </label>
    <div class="form-summ__grid grid gtc-1-auto">
      <input
        type="text"
        :name="'form-item-' + uid"
        class="form-summ__input form-item__input pl-10 pr-10"
        maxlength="13"
        :placeholder="placeholder"
        :required="required"
        v-model="propValue"
        @focus="propValue === '0' && (propValue = '')"
        @keypress="isNumber()"
      />

      <div class="form-summ__money" v-click-outside="closeAction">
        <button
          type="button"
          class="form-summ__money-btn btn pl-10 pr-10 t-upper fw-b"
          :class="isOpen && 'active'"
          :data-btn="uniqId"
          @click="toggleAction"
        >
          {{ propValueCurrency }}
          <!-- <BaseIcon class="ic-24 primary" :icon="propValueCurrency" /> -->
        </button>

        <div v-if="isOpen" class="form-summ__money-body box grid p-5" :data-body="uniqId">
          <button
            type="button"
            class="btn transparent-primary br-5 sm pl-10"
            :class="{ active: propValueCurrency === 'kzt' }"
            @click="setCurrentMoney('kzt')"
          >
            <b>KZT</b>
            <!-- <BaseIcon class="ic-20 primary" icon="kzt" /> -->
            Тенге
          </button>
          <button
            type="button"
            class="btn transparent-primary br-5 sm pl-10"
            :class="{ active: propValueCurrency === 'rub' }"
            @click="setCurrentMoney('rub')"
          >
            <b>RUB</b>
            <!-- <BaseIcon class="ic-20 primary" icon="rub" /> -->
            Рубль
          </button>
          <button
            type="button"
            class="btn transparent-primary br-5 sm pl-10"
            :class="{ active: propValueCurrency === 'usd' }"
            @click="setCurrentMoney('usd')"
          >
            <b>USD</b>
            <!-- <BaseIcon class="ic-20 primary" icon="usd" /> -->
            Доллар
          </button>
          <button
            type="button"
            class="btn transparent-primary br-5 sm pl-10"
            :class="{ active: propValueCurrency === 'eur' }"
            @click="setCurrentMoney('eur')"
          >
            <b>EUR</b>
            <!-- <BaseIcon class="ic-20 primary" icon="eur" /> -->
            Евро
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, defineProps, defineEmits, toRefs, ref, computed } from 'vue'
// import { BaseIcon } from '@/components'
import { calcCoords, $busOn } from '@/plugins'

// Emits
const emits = defineEmits(['update:modelValue', 'update:currencyValue'])

// Props
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Сумма'
  },
  required: {
    type: Boolean,
    default: false
  },
  direction: {
    type: String,
    default: 'rtl'
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  currencyValue: {
    type: String,
    default: 'kzt'
  }
})

// Data
const { label, placeholder, required, direction, modelValue, currencyValue } = toRefs(props)
const isOpen = ref(false)
const uid = getCurrentInstance().uid
const uniqId = 'summ-' + uid

// Computed
const propValue = computed({
  get: () => {
    const preNumb = modelValue.value.length > 13 ? modelValue.value.substr(0, 13) : modelValue.value
    const numb = preNumb.replace(/\s/g, '')
    return Number(numb).toLocaleString('ru-RU')
  },
  set: (val) => {
    const value = val.length > 13 ? val.substr(0, 13) : val
    emits('update:modelValue', value)
  }
})
const propValueCurrency = computed({
  get: () => currencyValue.value,
  set: (val) => emits('update:currencyValue', val)
})
const isSuccess = computed(() => {
  const num = propValue.value.replace(/\s/g, '')
  return !!Number(num)
})

// Created
$busOn('scrollAction', () => {
  if (isOpen.value) closeAction()
})

// Methods
function isNumber() {
  if (event.keyCode < 48 || event.keyCode > 57) event.returnValue = false
}
function toggleAction() {
  isOpen.value = !isOpen.value
  calcCoords(uniqId, direction.value)
}
function closeAction() {
  isOpen.value = false
}
function setCurrentMoney(money) {
  propValueCurrency.value = money
  closeAction()
}
</script>

<style lang="scss" scoped>
.form-summ {
  &__input {
    border-radius: var(--br) 0 0 var(--br);
  }

  &__money {
    &-btn {
      border-radius: 0 var(--br) var(--br) 0;
      box-shadow: inset 0 0 0 1px var(--bcolor);

      &.active {
        background-color: var(--primary);
        box-shadow: inset 0 0 0 2px var(--primary);
        color: #fff;

        & > svg {
          fill: #fff;
        }
      }
    }

    &-body {
      position: fixed;
      z-index: 1;
      width: fit-content;
      box-shadow: var(--box-shadow);
    }
  }
}
</style>
